#header {
  height: $header-height;
}

#nav-wrapper {
  height: 100%;
  display: flex;

  #sidenav {
    width: max(15vw, 150px);
  }

  #topnav {
    width: 100%;

    #nav-item {
      border-bottom: slategray 1px solid;
      border-right: slategray 1px solid;
      flex-grow: 1;
    }
  }

  #secondary-sidenav {
    width: max(20vw, 150px);
  }
}
