@font-face {
  font-family: __Inter_aaf875;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(fonts/organic-font.woff2) format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da,
    u+02dc, u+0304, u+0308, u+0329, u+2000-206f, u+2074, u+20ac, u+2122, u+2191,
    u+2193, u+2212, u+2215, u+feff, u+fffd;
}

@font-face {
  font-family: __Inter_Fallback_aaf875;
  src: local("Arial");
  ascent-override: 90.49%;
  descent-override: 22.56%;
  line-gap-override: 0%;
  size-adjust: 107.06%;
}
