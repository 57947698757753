#wrapper {
  position: fixed;
  top: $header-height;
  height: calc(100vh - $header-height);
  width: 100vw;
  overflow: auto;
}

.nav-layout-side {
  #navbar-wrapper {
    position: fixed;
    overflow: scroll;
    top: $header-height;
    height: calc(100vh - $header-height);
    border-right: #64748b 1px solid;
  }

  #main {
    position: fixed;
    overflow: scroll;
    top: $header-height;
    left: max(15vw, 150px);
    right: 0;
    height: calc(100vh - $header-height);
  }
}

.nav-layout-top {
  #main {
    flex: 1;
    height: 100%;
  }
}

// #main {
//   width: 100%;
// }

// .wrapper {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   padding: 0.7rem;

//   main {
//     flex-grow: 1;
//     display: flex;
//     flex-direction: column;
//   }
// }

// header,
// main,
// footer {
//   margin: 0 auto;
//   width: 100%;
// }
