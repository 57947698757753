html,
body,
#root,
#root > div {
  height: 100%;
}

body {
  font-family: __Inter_aaf875, __Inter_Fallback_aaf875;
  font-style: normal;
  --font-sans: "__Inter_aaf875", "__Inter_Fallback_aaf875";
}
